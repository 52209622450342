const state = {
  title: sessionStorage.getItem("title"),
  loginUrl: sessionStorage.getItem("loginUrl"),
};
// 获取
const getters = {
  get_Title(state) {
    return state.title;
  },
};
// 同步改变值
const mutations = {
  set_Title(state, title) {
    state.title = title;
    sessionStorage.setItem("title", title);
  },
  set_LoginUrl(state, loginUrl) {
    state.loginUrl = loginUrl;
    sessionStorage.setItem("loginUrl", loginUrl);
  },
};
// 异步改变
const actions = {
  titleActions(context, title) {
    context.commit("set_Title", title);
  },
  loginUrlActions(context, loginUrl) {
    context.commit("set_LoginUrl", loginUrl);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
