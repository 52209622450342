import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;

import ViewUI from "view-design";
// import style
import "view-design/dist/styles/iview.css";
Vue.use(ViewUI);

Vue.prototype.$htmlSize =
  (document.documentElement.clientWidth || document.body.clientWidth) / 1920;
window.addEventListener("resize", () => {
  Vue.prototype.$htmlSize =
    (document.documentElement.clientWidth || document.body.clientWidth) / 1920;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
