import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
  },
  // 通知公告
  {
    path: "/notice",
    name: "notice",
    component: () => import("../views/notice/index.vue"),
  },
  // 自行采购公告
  {
    path: "/to_purchase",
    name: "to_purchase",
    component: () => import("../views/to_purchase/index.vue"),
  },
  // 网上竞价平台
  {
    path: "/online_bidding",
    name: "online_bidding",
    component: () => import("../views/online_bidding/index.vue"),
  },
  // 列表详情
  {
    path: "/list_detail",
    name: "list_detail",
    component: () => import("../views/list_detail/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  // 切换路由后滚动条置顶
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0,
  //   };
  // },
});

export default router;
