<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>

<style lang="less">
@import "./assets/css/common.less";
* {
  margin: 0;
  padding: 0;
}
</style>
